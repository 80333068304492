import { AgeRange } from '@index/enums/age-range';
import { SkillLevel } from '@index/enums/skill-level';
import { SubscriptionType } from '@index/enums/subscription-type';
import {
  Location,
  Team,
  TeamWebsite,
  TeamWebsiteTemplate,
  UnregisteredTeamInfo,
  User,
} from '@index/interfaces';

import { GthModel } from './model';
import { GthTeamPlayerModel } from './player';
import { GthUserModel } from './user';

const DEFAULT_TEAM_TEXT = 'We haven\'t added any details yet 🙁';
const DEFAULT_TEAM_TEMPLATE: TeamWebsite = {
  template: TeamWebsiteTemplate.ONE,
  banner: '',
  photos: [],
  showRoster: false,
  showUpcomingGames: false,
  about: DEFAULT_TEAM_TEXT,
  contactEmail: 'N/A',
  contactPhone: 'N/A',
  socials: [],
  created: undefined,
  updated: undefined,
};

export class GthTeamModel extends GthModel<Team | UnregisteredTeamInfo> {
  public readonly tag = 'internal-team';
  private players: GthTeamPlayerModel[] = [];

  constructor(id: string, model: Team | UnregisteredTeamInfo) {
    super(id, model);

    if (!model) {
      return;
    }

    if ((model as Team).roster) {
      (model as Team).roster.forEach((p) => {
        if (p.player) {
          const player = new GthTeamPlayerModel(p.player.uid, p.rosterId ?? '', p);
          this.players.push(player);
        }
      });
    }
  }

  get id() {
    return this._id;
  }

  get description() {
    return (this.model as Team).description ? (this.model as Team).description : '';
  }

  get name() {
    return this.model.name;
  }
  set name(val: string) {
    this.model.name = val;
  }

  get online() {
    return !!this.model.online;
  }
  set online(val: boolean) {
    this.model.online = val;
  }

  get photoURL() {
    return this.model.photoURL;
  }
  set photoURL(val: string) {
    this.model.photoURL = val;
  }

  get sport() {
    if ((this.model as Team).sport) {
      return (this.model as Team).sport;
    } else if ((this.model as UnregisteredTeamInfo).gameType) {
      return (this.model as UnregisteredTeamInfo).gameType;
    }
    return '';
  }
  set sport(val: string) {
    if (this.model.id) {
      (this.model as Team).sport = val;
    } else {
      (this.model as UnregisteredTeamInfo).gameType = val;
    }
  }

  get roster() {
    return this.players;
  }
  set roster(players: GthTeamPlayerModel[]) {
    this.players = players ?? [];
  }
  get members() {
    const roles = ['Member', 'Owner'];
    return this.players?.filter((p) => roles.includes(p.role));
  }

  get discoverable() {
    return this.model.discoverable;
  }
  set discoverable(val: boolean) {
    this.model.discoverable = val;
  }

  get admins() {
    return this.roster.filter((p) => {
      return p.role === 'Admin' || p.role === 'Owner';
    });
  }

  get creator() {
    return this.roster.find((p) => p?.role === 'Owner');
  }

  get subscription() {
    return this.model.subscription ?? SubscriptionType.ROOKIE;
  }
  set subscription(sub: SubscriptionType) {
    this.model.subscription = sub;
  }

  get ageRange() {
    return this.model.ageRange;
  }

  set ageRange(val: AgeRange | string) {
    this.model.ageRange = val;
  }

  get location() {
    return this.model.location;
  }
  set location(val: Location | undefined) {
    this.model.location = val;
  }

  get created() {
    return this.model.created;
  }
  get updated() {
    return this.model.updated;
  }

  get website() {
    return this.model.website ? this.model.website : DEFAULT_TEAM_TEMPLATE;
  }
  set website(val: TeamWebsite) {
    this.model.website = val;
  }

  get displayPublicWebsite() {
    return this.model.displayPublicWebsite;
  }
  set displayPublicWebsite(val: boolean) {
    this.model.displayPublicWebsite = val;
  }

  get skillLevel() {
    const skillLevel = this.model.skillLevel;
    if (!skillLevel) {
      return [];
    }
    if (Array.isArray(skillLevel)) {
      return skillLevel;
    }

    return [skillLevel];
  }
  set skillLevel(val: SkillLevel[]) {
    this.model.skillLevel = val;
  }

  /**
 *
 * @param {GthTeamPlayerModel} player
 */
  addPlayer(player: GthTeamPlayerModel) {
    if ((this.model as Team).roster) {
      this.players.push(player);
      (this.model as Team).roster.push({
        player: player.player as unknown as User,
        role: player.role,
      });
    }
  }

  approvePlayer(userId: string) {
    console.log(this.players, userId);
    const player = this.players.find((p) => p.id === userId);
    if (!player) {
      throw new Error('Player not found in team.');
    }
    player.role = 'Member';
    return player;
  }

  denyPlayer(userId: string) {
    this.removePlayer(userId);
  }

  removePlayer(userId: string) {
    this.roster = this.roster.filter((p) => p.id != userId);
  }

  /**
   * Sets the Team Photo Url
   * @param {string} uri
   */
  setPhotoUrl(uri: string) {
    this.model.photoURL = uri;
  }

  clearAddress() {
    this.location = undefined;
  }

  isUserAdmin(user: GthUserModel) {
    const userId = user.uid;
    if (this.creator?.player?.uid === userId) {
      return true;
    }
    if (this.admins.some((a) => a.player?.uid === userId)) {
      return true;
    }
    return false;
  }
}
