import { AgeRange } from '@index/enums/age-range';
import firebase from 'firebase/compat/app';

import { SkillLevel } from '../enums/skill-level';
import { SubscriptionType } from '../enums/subscription-type';
import { Location } from './location';
import { User } from './user';

export interface Team {
  id: string;
  description: string;
  name: string;
  photoURL: string;
  sport: string;
  roster: RosterV2<User>[];
  discoverable: boolean;
  subscription: SubscriptionType;
  ageRange: AgeRange | string;
  location: Location;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  website: TeamWebsite;
  displayPublicWebsite: boolean;
  online: boolean;
  skillLevel: SkillLevel | Array<SkillLevel>;
}

export interface TeamWebsite {
  template: TeamWebsiteTemplate;
  banner: string;
  photos: string[];
  showRoster: boolean;
  showUpcomingGames: boolean;
  about: string;
  contactEmail: string;
  contactPhone: string;
  socials: string[];
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
}
export enum TeamWebsiteTemplate {
  ONE = 'one',
  TWO = 'two',
}

export interface TeamSurvey {
  id?: string;
  title: string;
  description: string;
  status: SurveyStatus;
  acceptResponses: boolean;
  questions: SurveyQuestion[];
  allowUpdatedResponses: boolean;
  requireAllQuestions: boolean;
  whoCanViewResults: SurveyResultsPermission;
  showResponses: boolean;
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  creator: string;
}
export interface SurveyResult {
  id?: string;
  questions: SurveyResultQuestion[];
  created?: firebase.firestore.Timestamp;
  updated?: firebase.firestore.Timestamp;
  user?: unknown;
}
export interface SurveyResultQuestion {
  label: string;
  answers: string[];
  answer: string;
}
export enum SurveyResultsPermission {
  TEAM_ADMINS = 'Team Admins',
  RESPONDENTS = 'Respondents',
  ANY = 'Any',
}
export interface SurveyQuestion {
  label: string;
  answers: string[];
  chartType: SurveyChartType;
  includeInSurvey: boolean;
  allowMultipleAnswersSelections: boolean;
  allowCommentsWithResponses: boolean;
  makeResponsesAnonymous: boolean;
}
export enum SurveyChartType {
  BAR = 'Bar',
  COLUMN = 'Column',
  LINE = 'Line',
}

export enum SurveyStatus {
  HIDDEN = 'Hidden',
  SHOWN = 'Shown',
}

export interface TeamRosterItem {
  id?: string;
  teamId: string;
  userId: string;
  role: string;
  email: string;
  unregisteredUser?: boolean;
}

export interface UpdateInterface {
  id: string;
  name?: string;
  sport?: string;
  players?: Player[];
}

export interface Player {
  name: string;
  email: string;
  userId: string;
  memberType: PlayerMemberType;
  image?: string;

  /**
   * When player is part of a game, set to true if rsvp
   */
  rsvp?: boolean;
}

export interface RosterV2<T> {
  player: T;
  role: string;

  /**
   * When player is part of a game, set to true if rsvp
   */
  rsvp?: boolean;
  rosterId?: string;
  team?: {
    id: string,
    name: string,
    photoURL: string,
  }
}

export interface TeamV2<T> {
  id: string;
  name: string;
  description: string;
  photoURL: string; // path
  sport: string; // string for now, will convert to enum.

  Roster?: RosterV2<T>[];
}

export enum PlayerMemberType {
  None = 0,
  Member = 1,
  Admin = 99,
}

export interface CreateTeamRequest {
  name: string;
  description: string;
  photoURL: string; // path
  sport: string;
  roster: RosterV2<User>[];
  discoverable: boolean;
  subscription: SubscriptionType;
  ageRange: AgeRange | string;
  location: Location;
  skillLevel: SkillLevel[];
}

export interface TeamReadRequest {
  teamId: string;
}

export interface TeamReadResponse {
  id: string;
  name: string;
  description: string;
  photoURL: string; // path

  // TODO: figure out how type this to the sports enums  https://app.clickup.com/t/1p2am42
  sport: string;
  roles: [];
  discoverable: boolean;
}

export interface UpdateTeamRequest {
  id: string;
  name?: string;
  description?: string;
  photoURL?: string; // path
  roster?: RosterV2<string>[];
  sport?: string; // Use Enum here, but for now will use string.
  // NOTE: roster is not included due to team memberships being managed separately
  discoverable?: boolean;
  subscription?: SubscriptionType;
  ageRange?: AgeRange | string;
  location?: Location;
  website?: TeamWebsite;
  displayPublicWebsite?: boolean;
  online?: boolean;
  skillLevel?: SkillLevel[],
}

export interface TeamReadResponse {
  id: string;
  name: string;
  description: string;
  photoURL: string; // path
  sport: string; // string for now, will convert to enum.

  Roster?: {
    player: { displayName: string; id?: string; photoURL: string };
    role: string;
  }[];
  discoverable: boolean;
} // this will need to be turned into a  json string.

export interface TeamReadRequest {
  id: string;
  name: string;
  description: string;
  photoURL: string; // path
  sport: string; // string for now, will convert to enum.

  Roster?: {
    player: { displayName: string; id?: string; photoURL: string };
    role: string;
  }[];
  discoverable: boolean;
} // this will need to be turned into a  json string.

export interface TeamListRequest {
  lat?: number;
  lng?: number;
}

export interface TeamListResponse {
  list: {
    id: string;
    name: string;
    description: string;
    photoURL: string;
    sport: string;
    roster?: {
      player: { displayName: string; id?: string; photoURL: string };
      role: string;
    }[];
    discoverable: boolean;
  }[];
}
